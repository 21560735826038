// WCC Projects > Pillar Awards
// src > ts > app.ts

// MODULES

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

// CONSTANTS

const firebaseConfig = {
  apiKey: "AIzaSyA5aSanGurF9NjpupsJyEbuSPy4vMVhZJk",
  authDomain: "pillar-awards.wccprojects.com",
  projectId: "pmhs-pillar-awards",
  storageBucket: "pmhs-pillar-awards.appspot.com",
  messagingSenderId: "512099274581",
  appId: "1:512099274581:web:d896512c3fd0ba23cfa9dc"
};

export const authentication = firebase.auth;
export const database = firebase.firestore;
export const functions = firebase.functions;

// FUNCTIONS

const initializeApp = () => {
  firebase.initializeApp(firebaseConfig);
};

// INITIAL SCRIPTS

initializeApp();
